<template>
  <section class="card-container">
    <div
      :id="`${content.objectid}`"
      class="card"
      :class="highlight ? borderColor : ''"
      @click="cardClickHandler(content)"
      :style="[{ height: `${cardType.height}` }, { width: `${cardType.width}` }, { margin: `${cardType.margin}` }]"
    >
      <imageItem class="carousel-image-item" :source="gotPosterItem" />

      <div
        class="play-img"
        id="play-imgs"
        v-if="cardType.playButton.enablePlay || (screen ? screen.sectionType === 'DIRECTPLAY' : '')"
      >
        <img
          id="play-img-item"
          src="@/assets/icons/Ic_Play1.svg"
          :width="cardType.playButton.enablePlay ? `${cardType.playButton.width}` : `${cardWidth}`"
          :height="cardType.playButton.enablePlay ? `${cardType.playButton.height}` : `${cardHeight}`"
        />
      </div>
      <div
      :class="[localDisplayLang === 'ara' ? 'top-10-numb-right' : 'top-10-numb']"
        id="play-imgs"
        v-if="screen && screen.sectionType === 'TOPRECOMMENDED'"
      >
      <img
        id="play-img-item"
        class="top-num-img"
        :src="localDisplayLang === 'ara' 
          ? require(`@/assets/icons/arabic/${num+1}.svg`) 
          : require(`@/assets/icons/${num+1}.svg`)"
        />
      </div>
      <div
        class="remove-btn"
        :class="[localDisplayLang === 'ara' ? 'remove-btn-ara' : 'remove-btn-eng']"
        v-if="showRemove"
      >
        <img src="@/assets/icons/Ic_remove_mylist.svg" @click.stop="removeFromWatchlist(content)" alt />
      </div>

      <div
        class="content-details"
        v-if="showTitle || (screen ? screen.sectionType === 'DIRECTPLAY' : '')"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <div v-if="content.category === 'MOVIE' && content.title && isContinueWatch">
          <p>{{ content.title }}</p>
        </div>
        <div v-if="content.category === 'TVSHOW' && content.seriesname">
          <p>{{ content.seriesname }}</p>
          <p>{{ `S${content.seasonnum} : ${content.title}` }}</p>
        </div>

        <!-- <div v-if="content.title !== undefined && isContinueWatch">
          <p v-if="content.title.length > 25">{{ content.title.slice(0, 25) }}...</p>
          <p v-else>
            <span v-if="content.seriesname"> {{ content.seriesname }} <br /> </span>
            <span v-else> {{ content.title }} <br /> </span>

            <span v-if="content.category === 'TVSHOW'">
              {{ content.objecttype === "SERIES" ? content.title : `S${content.seasonnum}:E${content.title}` }}
            </span>
          </p>
        </div>
        <div v-else>
          <p v-if="content.seriesname">
            {{ content.seriesname.length > 25 ? content.seriesname.slice(0, 25) + "..." : content.seriesname }}
          </p>
          <p v-if="content.seriesname">{{ `S${content.seasonnum}:E${content.episodenum}` }}</p>
        </div> -->
      </div>

      <div
        :class="[localDisplayLang === 'ara' ? 'trailer_tags_container_right' : 'trailer_tags_container']"
        v-if="content.contenttype === 'Trailer'"
      >
        <div class="trailer_tags_container_item" dir="ltr">
          <p>{{ calcTimeFormat(content.duration) }}</p>
        </div>
      </div>

      <div class="tags-container" v-if="displayTags">
        <div class="tags-container-item">
          <!--content tag container-->
          <div
            :class="[localDisplayLang === 'ara' ? 'content-tags-right' : 'content-tags']"
            v-if="isObjectTag(content)"
          >
            <div>
              <p>{{ pickTagForContent(content.objecttag[0]) }}</p>
            </div>
          </div>
          <!--seasons tag container-->
          <div
            :class="[localDisplayLang === 'ara' ? 'category-tags-right' : 'category-tags']"
            v-if="!screen.sectionType === 'TOPRECOMMENDED' && content.objecttype === 'SERIES' && content.seasoncount > 1"
          >
            <div>
              <img src="@/assets/icons/ic_episodes.svg" alt />
              <span v-if="content.objecttype === 'SERIES'">{{ content.seasoncount }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="percentProgress != undefined" class="gray-line" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"></div>
      <div
        v-if="percentProgress"
        class="progress-percent"
        :style="{ width: percentProgress + '%' }"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      ></div>
    </div>

    <div class="triangle" :class="triangleColor" v-if="highlight && !percentProgress"></div>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { _projectName } from "@/provider-config.js";
import { mapGetters, mapActions } from "vuex";
import { store } from "@/store/store";

export default {
  props: {
    content: {
      type: Object,
    },
    cardType: {
      type: Object,
    },
    screen: {
      type: Object,
    },
    percentProgress: {
      type: Number,
    },
    absoluteProgress: {
      type: Number,
    },
    id: {
      type: [String, Number],
    },
    num: {
      type: [String, Number],
    },
    showRemove: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean,
    },
    hiddenContent: {
      type: Object,
    },
    displayTags: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
    isDirectPlay: {
      type: Boolean,
    },
    isContinueWatch: {
      type: Boolean,
    },
  },
  data() {
    return {
      cardHeight: "40px",
      cardWidth: "40px",
      borderColor: null,
      highlight: false,
      cardWidth: "100%",
      gotPosterItem: null,
      triangleColor: null,
      isContentPlayable: false,
      availabilities: [],
      subscriptions: [],
      filteredAvailabilities: [],
      pricemodel: [],
      localDisplayLang: null,
      completeEpisodeListing: [],
      playlistIndex: -1,
      isMpegRequired: false,
      episodeList: [],
      totalEpisodeCount: 0,
      playbackInitiated: false,
    };
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
        // this.getAvailability(this.content);
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        // this.getAvailability(this.content);
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "availabilityList", "subscriptionList"]),
  },
  created() {
    console.log('Category Card content recieved through props',this.content)
    this.isMpegRequired = this.checkMpegRequired();

    this.getPoster();

    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    // card border color
    this.borderColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "border-color"
        : _projectName === "NAMMAFLIX"
        ? "border-color-white"
        : "";

    // card triangle Color
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
    eventBus.$on("re-render-carousel", () => {
      this.getPoster();
    });

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    console.log("sectionType", screen.sectionType)
    eventBus.$on("off", () => {
      this.highlight = false;
    });

    eventBus.$on(`highlight-${this.id}`, () => {
      this.highlight = true;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;

        this.playerInstance.deregisterEvent("currentcontentended");
        this.playerInstance.deregisterEvent("loadchosencontent");
        this.playerInstance.deregisterEvent("loadmorecontent");
      }
    });
  },
  methods: {
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(tag) {
      let contenttags = this.appConfig.contentTags;
      if (this.localDisplayLang === "ara") {
        return contenttags.ar[`${tag}`];
      } else {
        return contenttags.en[`${tag}`];
      }
    },
    cardClickHandler(content) {
      console.log("THE WATCH LSIT CLICK -----", this.isDirectPlay, content);
      console.log("THE WATCH LSIT CLICK -----", this.isDirectPlay, content);

      if (content.objecttype == "CONTENT" && this.isDirectPlay) {
        console.log("THIS IS DIRECT PLAY FROM CARD -- 2");

        if (this.$router.currentRoute.params && this.$router.currentRoute.params.section) {
          localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
        } else {
          localStorage.setItem("playback_source", "HOME");
        }

        this.playContent(content);
      } else {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          //this.openDetailPopup(content);
            if(content.category == "TVSHOW" ){
            // let title;
            //   if(this.localDisplayLang == "eng"){
            //     title = content.title.replace(/\s/g, '-')
            //   }
            //   else{
            //     title = "title"
            //   }
              let title = content.defaulttitle ? content.defaulttitle.replace(/\s/g, "-") : content.title.replace(/\s/g, "-");
              this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title.toLowerCase(), lang: currentLanguage   } });

          } else if(content.objecttype === "SEASON") {
              let title = content.seriesname ? content.seriesname.replace(/\s/g, "-") : content.title.replace(/\s/g, "-");
              this.$router.push({ name: "detailPage", params: { contentId: content.seriesid.toLowerCase() , mediaTitle:title.toLowerCase(), lang: currentLanguage     } });
            }
          else if(content.trailertype == "TRAILER"){
				this.showPlayer();
        // this.loadContentMetaData(this.contentDetails, null);
        // this.contentDetails.isDrmContent = false;
        this.content.trailerUrl = content.filelist[0].filename;


        let locationArr = location.pathname.split("/");
				console.log("locationArr",locationArr)


        // window.history.pushState(
        //   "object or string",
        //   {},
        //   "/" +
        //     locationArr[1] +
        //     "/trailer/" +
        //     content.trailerid +
        //     "/play"
        // );

        let contentList = [content];
        this.showPlayer();
      //let contentList = [this.content];
      this.loadContentMetaData(contentList[0], null);
      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
					}
          else{
          //  let title;
          //     if(this.localDisplayLang == "eng"){
          //       title = content.title.replace(/\s/g, '-')
          //     }
          //     else{
          //       title = "title"
          //     }
           let title = content.defaulttitle ? content.defaulttitle.replace(/\s/g, "-") : content.title.replace(/\s/g, "-");
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title.toLowerCase() , contentId: content.objectid.toLowerCase() ,lang: currentLanguage  } });
          }
        } else {
          console.log("I AM IN Detail ------------", content);
          //this.$router.push({ name: "detail", params: { contentid: content.objectid } });
        }
      }
        // if(content.category == "TVSHOW"){
        //     let title = content.title.replace(/\s/g, '-')
        //     this.$router.push({ name: "detailPage", params: { contentId: content.objectid , mediaTitle:title  } });

        //   }
        //   else{
        //     let title = content.title.replace(/\s/g, '-')
        //     this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid  } });
        //   }
      //let fullPath = this.$route.fullPath
       //console.log('what is the params------------', fullPath[fullPath.length-1])
      //  console.log
      // window.history.pushState("object or string", fullPath, "play");

      // if (content.objecttype == "CONTENT" && this.isDirectPlay) {
      //   console.log('it is a direct play component=======================')
      //   // console.log("THIS IS DIRECT PLAY FROM CARD -- 2");

      //   // if (this.$router.currentRoute.params && this.$router.currentRoute.params.section) {
      //   //   localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
      //   // } else {
      //   //   localStorage.setItem("playback_source", "HOME");
      //   // }

      //   // this.playContent(content);
      //   //this.$router.push({ name: "detailPageMovie", params: {contentName: content.title , contentId: content.objectid  } });
      // } else {
      //   if (screen.width > 0) {
      //     console.log('what is the content-----------------------',content)
          //this.openDetailPopup(content);
          // if(content.category == "TVSHOW"){
          //   let title = content.title.replace(/\s/g, '-')
          //   this.$router.push({ name: "detailPage", params: { contentId: content.objectid , mediaTitle:title  } });

      //     // }
      //     // else{
      //     //   let title = content.title.replace(/\s/g, '-')
      //     //   this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid  } });
      //     // }
      //   } else {
      //     console.log("I AM IN Detail ------------", content);
      //     //this.$router.push({ name: "detail", params: { contentid: content.objectid } });
      //   }
      // }
    },
		    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
    getPoster() {
			console.log("GETPOSTER",this.content)
      if (this.content.poster && this.content.poster.length) {
        let index = this.content.poster.findIndex((element) => {
          if (this.cardType.type === "LANDSCAPE") {
            return element.postertype === "LANDSCAPE";
          } else if (this.cardType.type === "PORTRAIT") {
            return element.postertype === "PORTRAIT";
          } else if (this.cardType.type === "SQUARE") {
            return element.postertype === "SQUARE";
          }
        });
        console.log("INDEX", index);
        if (index > -1) {
          if (screen.width > 768) {
            this.content.poster[index].filelist.filter((el) => {
              //adding thumbnail poster.
              if (this.cardType.quality === "THUMBNAIL" && el.quality === "THUMBNAIL") {
                this.gotPosterItem = el.filename;
              } else {
                if (el.quality === "LOW") {
                  // console.log("SD quality", el.filename);
                  this.gotPosterItem = el.filename;
                } else if (el.quality === "SD") {
                  this.gotPosterItem = el.filename;
                }
              }
            });
          } else {
            this.content.poster[index].filelist.filter((el) => {
              if (el.quality === "THUMBNAIL") {
                this.gotPosterItem = el.filename;
              }
            });
          }
        }
      } else if (this.content.watchedduration != undefined || this.content.inwatchlist) {
        this.gotPosterItem = this.content.poster.landscape;
      } else if (this.content.contenttype == "Trailer") {
        let getFilterPoster =
          this.content.poster &&
          this.content.poster.filelist &&
          this.content.poster.filelist.filter((item) => {
            if (item.quality == "THUMBNAIL") {
              return item.filename;
            }
          });
        this.gotPosterItem = getFilterPoster.length && getFilterPoster[0].filename;
        if (!this.gotPosterItem) {
          this.gotPosterItem =
            this.content.poster && this.content.poster.filelist && this.content.poster.filelist[0].filename;
        }
      }else if (this.content.trailertype == "TRAILER"){
				//alert("TRAILER-trailertype")
				    let getFilterPosterDecking =
          this.content.poster &&
          this.content.poster.filelist &&
          this.content.poster.filelist.filter((item) => {
            if (item.quality == "THUMBNAIL") {
              return item.filename;
            }
          });
					console.log("THUMBNAIL-CHECK",getFilterPosterDecking)
        this.gotPosterItem = getFilterPosterDecking.length && getFilterPosterDecking.filename;
        if (!this.gotPosterItem) {
          this.gotPosterItem =
            this.content.poster && this.content.poster.filelist && this.content.poster.filelist[0].filename;
        }
			}
    },
   showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
      this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    playContent(content) {
      console.log("THE Play Button", this.isDirectPlay);

      if (this.isDirectPlay && this.subscriberid) {
        this.showPlayer();

        if (content.category == "MOVIE") {
          let episodeIndex = 0;
          this.episodeList = [content];
          this.totalEpisodeCount = 1;

          this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
        } else {
          let returnCode = this.fetchEpisodeRecursively(this.fetchEpisodes, content, this.checkEpisodeInList, false);

          returnCode
            .then(() => {
              let episodeIndex = this.getEpisodeIndexInList(content.objectid, this.episodeList);

              this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
            })
            .catch((error) => {
              console.log("Error encountered : ", error);
            });
        }
      } else if (this.subscriberid && !this.isDirectPlay) {
        console.log("THIS IS DIRECT PLAY FROM CARD");
        this.$emit("play", content);
      } else if (!this.subscriberid) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    removeFromWatchlist(content) {
      this.$emit("remove", content);
    },
  },
  components: {
    imageItem: () => import(/* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"),
  },
  mixins: [Utility, PlaybackUtilities],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.border-color {
  border: 2px solid $btn-clr;
}
.border-color-white {
  border: 2px solid $clr-light-gd2; //nammaflix color
}
@keyframes scaledown {
  0% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.12);
  }
}
.card-container {
   
  position: relative;
  transition: all 0.4s ease-in-out;
  .card {
   
    .carousel-image-item {
      
      transition: all 0.3s ease-in;
      &:focus {
        outline: none;
      }
      &:hover {
        transform: scale(1.05);
        // box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.4);
      }
    }
    overflow: hidden;
    background: #f4f4f4;
    border-radius: 4px;
    // width: 100%;
    position: relative;
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    .play-img {
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      img {
        transition: all 0.4s ease-in-out;
        // opacity: 0;
        cursor: pointer;
      }
    }
    .remove-btn {
      position: absolute;

      img {
        transition: all 0.4s ease-in-out;
        width: 60%;
        opacity: 0;
        cursor: pointer;
      }
    }
    .remove-btn-ara {
      top: 2%;
      left: -4%;
    }
    .remove-btn-eng {
      top: 2%;
      right: -4%;
    }
    .content-details {
      position: absolute;
      color: white;
      font-family: $font-regular;
      width: 100%;
      padding: 5px;
      border-radius: 0px;
      bottom: 0%;
      font-weight: 600;
      opacity: 1;
      line-height: 24px;
      transition: all 0.4s ease-in-out;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.02) 0%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.7) 60%,
        rgba(0, 0, 0, 1) 90%
      );
    }
    .trailer_tags_container {
      position: absolute;
      top: 5px;
      left: 5px;
      &_item {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
        border-radius: 5px;
        font-size: 0.8rem;
        font-family: $font-regular;
      }
    }
    .trailer_tags_container_right {
      position: absolute;
      top: 5px;
      right: 5px;
      &_item {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
        border-radius: 5px;
        font-size: 0.8rem;
        font-family: $font-regular;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      // transform: scale(1.01);
      // box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.4);
      // .play-img {
      //   z-index: 1;
      //   img {
      //     opacity: 1;
      //   }
      // }
      .remove-btn {
        z-index: 2;
        img {
          opacity: 1;
        }
      }
      // .content-details {
      //   opacity: 1;
      // }
    }
  }
  .tags-container {
    position: absolute;
    top: 2%;
    width: 100%;
    .tags-container-item {
      position: relative;
      .content-tags {
        position: absolute;
        left: 0;
        padding: 6px 10px;
        background: $btn-clr-new;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        p {
          font-family: $font-regular;
          font-size: 0.7rem;
        }
      }
      .content-tags-right {
        position: absolute;
        right: 0;
        padding: 6px 10px;
        background: $btn-clr-new;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        p {
          font-family: $font-regular;
          font-size: 0.7rem;
        }
      }
      .category-tags {
        position: absolute;
        right: 0;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.5);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        img {
          height: 10px;
          width: 10px;
        }
        span {
          font-size: 0.7rem;
          font-weight: bold;
          font-family: $font-regular;
        }
      }
      .category-tags-right {
        position: absolute;
        top: 5%;
        left: 0%;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.5);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        img {
          height: 10px;
          width: 10px;
        }
        span {
          font-size: 0.7rem;
          font-weight: bold;
          font-family: $font-regular;
        }
      }
    }
  }
  .live-tag {
    position: absolute;
    top: 9%;
    right: 7%;
  }
  .play-btn {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20%;
      opacity: 0;
    }
  }
  .progress-percent {
    position: absolute;
    left: 0%;
    bottom: 0%;
    height: 2%;
    border-bottom-left-radius: 10px;
    background: $btn-clr-new;
  }
  .gray-line {
    background-color: rgba(255, 255, 255, 0.4);
    height: 2%;
    position: absolute;
    width: 100%;
    bottom: 0%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .triangle {
    margin: auto;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .triangle-color {
    border-top: 10px solid $btn-clr;
  }
  .triangle-color-white {
    border-top: 10px solid $clr-light-gd2;
  }
  &:focus {
    outline: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .card-container {
    .card {
      .play-img {
        img {
          opacity: 1;
          height: 30px;
          width: 30px;
        }
      }
      .remove-btn {
        img {
          opacity: 1;
          width: 45%;
        }
      }
      .remove-btn-ara {
        top: 2%;
        left: -8%;
      }
      .remove-btn-eng {
        top: 5%;
        right: -10%;
      }
      .content-details {
        font-size: 0.7rem;
        line-height: 14px;
      }
      .trailer_tags_container {
        &_item {
          font-size: 0.6rem;
        }
      }
      .trailer_tags_container_right {
        &_item {
          font-size: 0.6rem;
        }
      }
    }
    .tags-container {
      .tags-container-item {
        .content-tags,
        .content-tags-right {
          padding: 5px;
          p {
            font-size: 0.5rem;
          }
        }
        .content-tags-right {
          p {
            font-size: 0.5rem;
          }
        }
        .category-tags,
        .category-tags-right {
          padding: 1px 5px;
          img {
            width: 10px;
            height: 10px;
          }
          span {
            padding-left: 0;
            font-size: 0.6rem;
          }
        }
        .category-tags-right {
          padding: 2px 5px;
        }
      }
    }
    &:hover {
      outline: none;
    }
  }
}
.top-10-numb {
  // height: 100%;
          //width: 90%;
  /* justify-content: center; */
  // align-items: center;
  // display: flex;
         // position: absolute;
  // top: 0%;
        // bottom: 0%;
        // left: 0%;
  // right: 0%;


      /* width: 32%; */
    /* justify-content: center; */
    position: absolute;
    bottom: 0%;
    width: 20%;
    /* left: 0%; */
    top: 0%;
    right: 0%;
}
.top-10-numb-right {
  position: absolute;
    bottom: 0%;
    width: 20%;
     left: 0%; 
    top: 0%;
   // right: 0%;
}
.top-num-img{
  width: 100%;
  // transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 7, 6, 7, 0, -14, 60, 8, 1);
}
</style>
